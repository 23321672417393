import React from "react";

const FooterNew = ({ bgColor }) => {
  return (
    <>
      <div
        className="  py-4 relative   text-white flex items-center 
      justify-between h-72 lg:h-64"
        style={{ backgroundColor: bgColor }}
      >
        <div className="flex flex-col items-start mt-4 space-y-4 pl-4 lg:pl-28 justify-start">
          <div className="flex items-center justify-center">
            <img
              src="/images/white_2-1.png"
              alt="appspace solutions"
              className="w-10 lg:w-14 h-10 lg:h-14"
            />
            <div>
              <h2 className="text-xs lg:text-sm font-bold">AppSpace</h2>
              <h2 className="text-xs lg:text-sm">Solutions</h2>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start space-y-6 lg:space-y-0 justify-center lg:space-x-16">
            <div className="flex flex-col space-y-2">
              <div className="text-sm font-bold flex items-center justify-start space-x-4">
                <img
                  src="/images/icon/location1.svg"
                  alt="location"
                  className="w-8 lg:w-10"
                />
                <h2 className="text-xs w-56 lg:text-sm">
                  {" "}
                  Plot No. 29, Sector 142 Noida
                </h2>
              </div>
              {/* <div className="text-sm font-bold flex items-center justify-start space-x-4">
                <img
                  src="/images/icon/mobile1.svg"
                  alt="Mobile"
                  className="w-8 lg:w-10"
                />
                <h2 className="text-xs lg:text-sm"> +91 82793 23413</h2>
              </div> */}
              <div className="text-sm font-bold flex items-center justify-start space-x-4">
                <img
                  src="/images/icon/email1.svg"
                  alt="Email"
                  className="w-8 lg:w-10"
                />
                <h2 className="text-xs lg:text-sm">
                  {" "}
                  feedback@appspacesolutions.in
                </h2>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <h2 className="text-lg font-bold">Follow Us On </h2>
              <div className="flex items-center space-x-1 mt-2 mb-3 lg:mb-0 justify-center">
                <a
                  href="https://www.facebook.com/profile.php?id=100090372961738"
                  className="cursor-pointer"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    className="w-8 h-8"
                    src="/images/icon/icons8-facebook.svg"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/appspaceoffical/"
                  className="cursor-pointer"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    className="w-8 h-8"
                    src="/images/icon/icons8-instagram-96.png"
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://in.pinterest.com/appspacesolutions/"
                  className="cursor-pointer"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    className="w-8 h-8"
                    src="/images/icon/icons8-pinterest.svg"
                    alt="Piniterest"
                  />
                </a>
                <a
                  href="https://twitter.com/AppSpaceSolu"
                  className="cursor-pointer"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    className="w-8 h-8"
                    src="/images/icon/icons8-twitter-96.png"
                    alt="Twitter"
                  />
                </a>
                {/* <img className='w-8 h-8' src="/images/icon/icons8-linkedin-circled-96.svg" alt="" /> */}
                {/* <img className='w-8 h-8' src="/images/icon/icons8-youtube.svg" alt="" /> */}
              </div>
            </div>
          </div>
        </div>

        <img
          src="/images/Bottom_image.png"
          className="h-full hidden lg:block absolute -bottom-16 w-96 object-cover  right-0 "
          alt="mountain"
        />
      </div>

      <div
        className="flex h-16 text-white   items-center space-x-6 justify-center"
        style={{ backgroundColor: bgColor }}
      >
        <a href="/" className="text-white cursor-pointer">
          <h3 className="text-[16px]">Home</h3>
        </a>
        <a href="/about" className="text-white cursor-pointer">
          <h3 className="text-[16px]">About </h3>
        </a>
        <a href="/contact" className="text-white cursor-pointer">
          <h3 className="text-[16px]">Contact </h3>
        </a>
        <a href="/privacy" className="text-white cursor-pointer">
          <h3 className="text-[16px]">Privacy</h3>
        </a>
      </div>
    </>
  );
};

export default FooterNew;
