// import { getAnalytics} from 'firebase/analytics'
import { setDoc, doc } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { firestore } from "../firebase";
import AppHeader from "../Comp/AppHeader";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [detail, setDetail] = useState("");
  const [phone, setPhone] = useState("");
  const [succesfull, setSuccesfull] = useState(false);
  const [validate, setValidate] = useState(false);
  // const analytics=getAnalytics()
  const form = useRef();

  const submitHandler = (e) => {
    console.log("Submit Clicked");
    console.log(validate);
    //   logEvent(analytics,"select_content",{
    //     items:{name:"HomeScreen_Form_Section",
    //           event:"Form_Submit_Clicked"
    // }
    // })
    e.preventDefault();
    if (name.length > 0 && email.length > 0 && detail.length > 0) {
      // emailjs.sendForm('service_fy5xe44', 'template_ajifg4q', form.current, '0BGMfbaqPEuqyLtle')
      // .then((result) => {
      //     console.log(result.text);
      // }, (error) => {
      //     console.log(error.text);
      // });
      setDoc(doc(firestore, "user", email), {
        name: name,
        email: email,
        phone: phone,
        detail: detail,
      })
        .then(() => {
          setValidate(false);
          setName("");
          setEmail("");
          setDetail("");
          setPhone("");
          alert("Feedback Submitted Succesfully");
          setSuccesfull(true);
          console.log("Succesfully Completed Form");
        })
        .catch((error) => console.log(error));
    } else {
      setValidate(true);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AppSpace Solutions | Contact us page</title>
        <meta
          name="description"
          content="AppSpace Solutions s a top mobile App development company, Get in touch for application feedback and more."
        />
      </Helmet>
      <AppHeader name="contact" />
      <div className="flex flex-col space-y-10 mb-20 lg:space-y-0 lg:flex-row items-center justify-center pt-32 overflow-hidden px-10 md:px-32">
        <div className="flex flex-col pl-20 lg:pl-0  py-4  items-start justify-start space-y-10">
          <div className="flex items-center space-x-2 justify-start">
            <img src="/images/icon/email.svg" alt="Email" className="w-10" />
            <div>
              <h2 className="text-lg font-bold">Email Us</h2>
              <p className="text-xs">feedback@appspacesolutions.in</p>
            </div>
          </div>
          {/* <div className="flex items-center space-x-2 justify-start">
            <img src="/images/icon/mobile.svg" alt="Mobile" className="w-10" />
            <div>
              <h2 className="text-lg font-bold">Contact Us</h2>
              <p className="text-xs"> +91 82793 23413</p>
            </div>
          </div> */}
          <div className="flex items-center space-x-2 justify-start">
            <img
              src="/images/icon/location.svg"
              alt="Visit Us"
              className="w-10"
            />
            <div>
              <h2 className="text-lg font-bold">Visit Us</h2>
              <p className="w-96 text-xs">plot no 29, Sector 142 Noida</p>
            </div>
          </div>
          <img
            alt="map"
            className="w-96 h-[250px] object-cover  hidden lg:block rounded-xl drop-shadow-md"
            src="/images/new/place.jpg"
          />
        </div>
        <div
          className="bg-[#558CF7] drop-shadow-xl rounded-xl  lg:w-96 px-4 py-6 pr-16 lg:pr-4 
        "
        >
          <h2 className="text-white text-2xl font-semibold ">
            <strong className="text-gray-700">Any Feedback ?</strong> We would
            love to hear them.
          </h2>
          <p className="text-white text-[10px] font-thin">
            Kindly share the feedback and the relevant details required.
          </p>
          <form ref={form} action="submit" onSubmit={submitHandler}>
            <div className="flex items-center justify-between mt-8 space-x-10">
              <div>
                <h2 className="text-[10px] text-white"> Name</h2>
                <input
                  type="text"
                  name="user_name"
                  aria-label="Full name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" text-white text-[12px]  bg-[#558CF7] border-[0.5px] rounded-sm w-44"
                />
              </div>
              {/* <div>
            <h2 className='text-[10px] text-white'>Last Name</h2>
             <input type="text" name="Name"  id="Second Name" className=' text-white text-[12px]   bg-[#558CF7] border-[0.5px] rounded-sm w-32' />
            </div> */}
            </div>
            <div className="mt-4">
              <h2 className="text-[10px] text-white">Email</h2>
              <input
                type="email"
                name="user_email"
                aria-label="Full name"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className=" text-white text-[12px]   bg-[#558CF7] border-[0.5px] 
          rounded-sm w-44"
              />
            </div>
            <div className="mt-4">
              <h2 className="text-[10px] text-white">Phone number</h2>
              <input
                id="telNo"
                name="telNo"
                type="tel"
                size="20"
                minlength="9"
                maxlength="14"
                required={false}
                aria-label="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className=" bg-[#558CF7] text-white  border-[0.5px] rounded-sm w-44"
              />
            </div>
            <div className="mt-4">
              <h2 className="text-[10px] text-white ">Message</h2>
              <textarea
                type="text"
                name="user_message"
                aria-label="Full name"
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
                className="text-white text-[12px] resize-none    h-10  bg-[#558CF7] border-[0.5px] rounded-sm w-44"
              />
            </div>
            <button
              disabled={succesfull}
              sucess={succesfull}
              type="submit"
              className={`w-40 mt-3  text-white h-10 rounded-md ${
                succesfull === true ? "bg-gray-500" : "bg-[#FF6200]"
              } p-2 `}
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
